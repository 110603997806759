import React from 'react'
import Player from "@madzadev/audio-player";

import AukeraBerriak from '../Canciones/Aukera Berriak.mp3'
import AurkituGenituen from '../Canciones/Aurkitu Genituen.mp3'
import Errepidean from '../Canciones/Errepidean.mp3'
//import GeldituDenbora from '../Canciones/Gelditu Denbora.mp3'
import GuGeuGarena from '../Canciones/Gu Geu Garena.mp3'
import Guretzat from '../Canciones/Guretzat.mp3'
import HeldudaGaraia from '../Canciones/Heldu da Garaia.mp3'
import KantuBat from '../Canciones/Kantu Bat.mp3'
import ZurekinBatera from '../Canciones/Zurekin Batera.mp3'
import LaGent from '../Canciones/La Gent Que Estimo.mp3'

const tracks = [
    {
        url: AukeraBerriak,
        title: "Aukera Berriak",
        tags: [],
    },
    {
        url: AurkituGenituen,
        title: "Aurkitu Genituen",
        tags: [],
    },
    {
        url: Errepidean,
        title: "Errepidean",
        tags: [],
    },
/*    {
        url: GeldituDenbora,
        title: "Gelditu Denbora",
        tags: [],
    }, */
    {
        url: GuGeuGarena,
        title: "Gu Geu Garena",
        tags: [],
    },
    {
        url: Guretzat,
        title: "Guretzat",
        tags: [],
    },
    {
        url: HeldudaGaraia,
        title: "Heldu da Garaia",
        tags: [],
    },   
    {
        url: KantuBat,
        title: "Kantu Bat",
        tags: [],
    },
    {
        url: ZurekinBatera,
        title: "Zurekin Batera",
        tags: [],
    },
    {
        url: LaGent,
        title: "La Gent Que Estimo",
        tags: [],
    },
];

export const Player1 = () => {
    return (
        <div>
            <Player trackList={tracks} />
        </div>
    )
}
