import './App.css';
import './index.css';
import { Player1 } from './components/Player1';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Player1 />
      </header>
    </div>
  );
}

export default App;
